.cardmd {
	margin-top: 25px;
	img {
		background: #f5f6ff;
		border-radius: 8px;
		margin-bottom: 30px;
		border-radius: 5px;
		-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
		transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
		height: 51px;
		width: 51px;
		&:after {
			content: "";
			z-index: -1;
			opacity: 0;
			-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
			transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
		}
		&:hover {
			-webkit-transform: scale(1.25, 1.25);
			transform: scale(1.25, 1.25);
			cursor: pointer;
			animation-name: bounce;
			-moz-animation-name: bounce;
			&::after {
				opacity: 1;
			}
		}
	}
	h5 {
		font-weight: bold;
		font-size: 20px;
		line-height: 20px;
	}
	p {
		font-weight: normal;
		font-size: 18px;
		line-height: 25px;
	}
}

@media screen and (max-width: 767px) {
	.cardmd {
		margin-top: 20px;
		p {
			font-size: 16px;
			line-height: 22.4px;
		}
	}
}

@media screen and (min-width: 300px) and (max-width: 991px) {
	.cardmd {
		.cardmd-container {
			display: flex;
			column-gap: 50px;
		}
	}
}

@media screen and (min-width: 300px) {
	.cardmd {
		.cardmd-container {
			column-gap: 20px;
			align-items: flex-start;
		}
	}
}
