.waitlist {

	.psmall {
		max-width: 100%;
	}

	.countryselect {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    cursor: pointer;
    // border: 1px solid #fff;
    border-radius: 12px;
    min-width: 10rem;

	img {
		margin-right: 8px;
    border-radius: 100%;
    border: 2px solid rgba(0, 0, 0, 0.03);
    width: 20px;
    height: 20px;
	}

	.flag {
		display: flex;
    flex-direction: row;
    padding-left: 10px;
    margin-top: 7px;
    margin-bottom: -7px;

	p {
		margin-top: 2px;
	}
	}

	.dropdown-menu {
		background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.03);
	// padding: 0;
	padding-top: 0px;

	}

	.dropdown-toggle::after {
		color: #fff;
	}
}

.countryselectflagimg {
    display: flex;
    flex-direction: row;
    border: 1px solid #fff;
    align-items: center;
    justify-content: center;
    padding-bottom: 0px;
    border-radius: 8px;
    padding-right: 10px;
    max-height: 30px;
    min-width: 10rem;
}
	.countryselectflagimg p {
    margin-top: 17px;
    padding-right: 53px !important;
}
	.hero-main-wrapper {
		margin-top: -90px;

		@media screen  and (max-width:767px){
	    margin-top: -180px !important;
	}
	}

	// .countryselect img {
	// 	border: 1px solid #070716;
	// }
	
	background: #070715 !important;
	.logotagline {
		color: #fff !important;
	}

	.HeroMainDescription {
		span {
			color: #7273db !important;
			// font-weight: lighter !important;
	
		}
	}

	input:focus {
    box-shadow: none !important;
    border: 2px solid #7273db !important;
}

	.countryname {
		color: #fff !important;
	}
	.span1span1span1span1span1 {
		display: none;
	}
	.hero-main {
		p {
			width: 100% !important;
			color: #fff !important;
		}

		.hero-typingText {
			color: #fff;
		}
		span {
			color: #fff;
		}
		h1 {
			color: #fff !important;
		}
		.btn-hero-button {
			display: none !important;
		}
	}
	.footer2copyright {
		p {
			color: #fff !important;
		}
	}
	.why-lenco {
		color: #fff;
		text-align: center;
		font-size: 54px;
		line-height: 70px;
	}
	.countryselect {
		// .dropdown-toggle {
		// 	&::after {
		// 		margin-top: 16px !important;
		// 		color: #fff;
		// 	}
		// }
		.countryselectform {
			background-color: #070715;
			border: none;
			height: 20px;
			color: #fff;
			margin-top: 10px;
		}
	}
	.landingpages {
		height: 99vh;
		padding-top: 180px;
	}
	.join-list {
		color: #fff !important;
		text-align: center;
		font-size: 54px;
		line-height: 70px;
		padding-bottom: 50px;
		font-weight: bold;
	}
	.hero-main-wrapper {
		padding-top: 150px;
	}
	.item {
		background: #070715 !important;
	}
	.footer2section-two-info {
		margin-top: 150px;
	}
	.section-twelve {
		h2 {
			font-size: 24px !important;
			line-height: 34px !important;
		}
	}
	.never-again {
		font-size: 20px;
		color: #fff !important;
		text-align: center;
		padding-bottom: 20px;
	}
	.subscribe-landingpages-form {
		padding-bottom: 200px !important;
	}
	.carousel__container {
		position: relative;
		overflow-y: hidden;
		width: 100%;
		height: 400px;
		overflow: hidden;
	}
	.owl-dots {
		position: absolute;
		top: 40%;
		transform: rotate(90deg);
		left: 0;
		margin-left: -80px;
	}
	.navigation {
		background: #070715 !important;
		.nav-link {
			color: #fff !important;
		}
	}
	.navbar-light {
		.navbar-toggler-icon {
			background-image: url("https://res.cloudinary.com/beyondlogic/image/upload/v1632825296/Vector_ezqt4h.svg");
		}
	}
	.footer {
		background: #070715 !important;
		h2 {
			color: #fff !important;
		}
		h3 {
			color: #fff !important;
		}
		p {
			color: #fff !important;
		}
		ul {
			li {
				color: #fff !important;
			}
		}
	}
	.btn-default {
		background: #7273db;
		border-radius: 8px;
		border-color: #7273db;
		cursor: pointer !important;
	}
	.navbar-toggler {
		background-color: #070715 !important;
	}
}
@media screen and (min-width: 1200px) {
	.waitlist {
		.hero-main {
			p {
				padding-left: 130px;
				padding-right: 130px;
			}
		}
		.subscribe-landingpages-form {
			padding-top: 300px !important;
		}
	}
}

@media screen and (min-width:451px) and (max-width:767px) {
	.waitlist {
		.subscribe-form {
			width: 100% !important;
		}
		.join-list {
			font-size: 24px !important;
			line-height: 24px !important;
			padding-left: 30px !important;
			padding-right: 30px !important;
			margin-top: 50px;
		}
	}
}
@media screen and (max-width:450px) {
	.waitlist {
		.subscribe-form {
			width: 100% !important;
		}
		.join-list {
			font-size: 24px !important;
			line-height: 24px !important;
			padding-left: 10px !important;
			padding-right: 10px !important;
			margin-top: 50px;
		}
	}
}
@media screen and (max-width:991px) {
	.waitlist {
		.join-list {
			font-size: 34px !important;
			line-height: 40px !important;
			padding-left: 50px;
			padding-right: 50px;
		}
		.landingpages {
			height: auto;
			padding-top: 50px;
		}
		.hero-main-wrapper {
			// padding-top: 150px;
			margin-bottom: -150px;
			margin-top: -100px;
		}
	}
	.section-12-2 {
		min-height: auto;
	}
	.section-12-1 {
		min-height: auto;
	}
}
@media screen and (min-width: 1200px) {
	.waitlist {
		.why-lenco {
			padding-bottom: 30px;
		}
		.waitlist {
			.hero-main-wrapper {
				margin-bottom: -50px;
			}
		}
		.landingpages {
			height: 99vh;
			padding-top: 180px;
		}
	}
}

@media screen and (min-width: 1024px) and (max-width:1199px) {
	.waitlist {
		.why-lenco {
			padding-bottom: 30px;
		}
		.waitlist {
			.hero-main-wrapper {
				margin-bottom: -50px;
			}
		}
		.landingpages {
			height:auto !important;
			padding-top: 50px !important;
		}

		.hero-featured img {
			width: 100% !important;
			display: inline-block !important;
		}
	}
}
@media screen and (min-width: 1201px) and (max-width:1230px) {
	.waitlist {
		.subscribe-landingpages-form {
			margin-top: -5rem !important;
		}
	}
}
@media screen and (min-width:1231px) {
	.waitlist {
		.subscribe-landingpages-form {
			margin-top: -10rem !important;
		}
	}
}
@media screen and (min-width:1232px) {
	.waitlist {
		.subscribe-landingpages-form {
			margin-top: -10rem !important;
		}
	}
}
@media screen and (min-width:1250px) {
	.waitlist {
		.subscribe-landingpages-form {
			margin-top: -15rem !important;
		}
	}
}
@media screen and (min-width:1200px) {
	.section-12-1 {
		padding-top: 120px;
	}
}
// @media screen and (max-width: 1024px) {
// 	.waitlist {
// 		.hero-main {
// 			h1 {
// 				font-size: 36px !important;
// 				line-height: 43px !important;
// 				width: 98%;
// 				margin: auto;
// 			}
// 		}
// 	}
// }
@media screen and (max-width: 767px) {
	.waitlist {
		.hero-main {
			p {
				line-height: 22px;
			}
		}
		.countryselect {
			.countryselectform {
				width: 10px;
			}
		}

		.hero-featured {
    margin-top: -50px !important;
    margin-bottom: 200px !important;
    
	img {
		display: block !important;
		width: 100% !important;
	}
}
	}
}
