.btn-default {
	background: #5260fd;
	border-radius: 8px;
	border-color: #5260fd;
	color: #fff;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: #ffffff;
	padding: 10px 16px;
	&:hover {
		background-color: #081fde;
		color: #fff;
	}
}
.btn-default-footer {
	padding: 20px 23.53px;
	border-radius: 15px;
}
.btn-hero-button {
	position: relative;
	padding: 15px 25px;
	cursor: pointer;
	z-index: 20;
}

.btn-default:focus {
	box-shadow: none !important;
}

.btn-ghost {
	background-color: transparent;
	border: none;
	color: #5260fd;

	&:hover {
		background-color: transparent;
		color: #081fde;
	}
}

.btn {
	font-weight: 400;
}
