.section-eight {
	margin-bottom: 50px;
	.section-eight-wrapper div {
		margin-bottom: 20px;
	}

	.title {
		font-weight: bold;
		font-size: 36px;
		line-height: 50.4px;
		margin-bottom: 40px;
		text-align: left;
	}
	h4 {
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
		margin-bottom: 20px;
		margin-top: 18px;
	}
}

@media screen and (max-width: 767px) {
	.section-eight {
		margin-top: 50px !important;
		padding-top: 0 !important;
		.title {
			font-size: 24px;
			line-height: 35px;
		}
		h4 {
			font-size: 24px;
			line-height: 24px;
			margin-top: 50px;
			margin-bottom: 30px;
		}
	}
	.section-eight-wrapper {
		display: flex;
		flex-flow: column-reverse;
		.col-lg-7 {
			margin-bottom: 20px;
		}
	}
}
@media screen and (min-width: 768px) and (max-width: 991px) {
	.section-eight {
		margin-top: 50px !important;
		padding-top: 50px !important;
		.title {
			font-size: 30px;
			line-height: 39px;
		}
		h4 {
			font-size: 24px;
			line-height: 24px;
			margin-top: 80px;
			margin-bottom: 30px;
		}
	}
	.section-eight-wrapper {
		display: flex;
		flex-flow: column-reverse;

		> div {
			margin-bottom: 20px;
		}
	}
}
