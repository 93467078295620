.countryselect {
	display: flex;
	flex-direction: row;
	font-weight: 500;
	font-size: 16px;
	line-height: 14px;
	.countrySelectContainer {
		cursor: pointer;
	}
	img {
		margin-right: 8px;
		border-radius: 100%;
		
    border: 1px solid #41415A;
		width: 20px;
		height: 20px;
	}
 
    .country-container{
		display: flex;
		align-items: center;
	}
	.country-item1:hover {
 background-color: #F5F6FF;
 border-top-left-radius: 8px;
  border-top-right-radius: 8px;
      
	}

	.country-item2:hover {
 background-color: #F5F6FF;
 border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
      
	}
	
	.countryselectform {
		background-color: #f3f4ff;
		border: none;
	}
	.empty {
		display: none;
	}
	.flag {
		display: flex;
		flex-direction: row;
		padding-left: 10px;
		margin-top: 4px;
		margin-bottom: -7px;
	}
	.dropdown-menu {
		border: none;
		background: #FFFFFF;
		border-radius: 8px;
		border: 1px solid rgb(0 0 0 / 3%);
		padding-top: 0px;
		padding-bottom: 0px;
	}
	.dropdown-toggle {
		&::after {
			// margin-top: 11px !important;
			display: inline-block;
			width: .3em;
			height: .3em;
			// margin: -.3em 0 0 .4em;
			vertical-align: middle;
			content: "";
			border: .3em solid;
			border-width: 0 .15em .15em 0;
			transform: rotateZ(45deg);
			
			
		}
	}

	
}
.countryselectflagimg {
	display: flex;
	flex-direction: row;
	border: 1px solid rgb(0 0 0 / 3%);
	align-items: center;
	justify-content: center;
	padding-bottom: 0px;
	border-radius: 8px;
	padding-right: 10px;
	max-height: 30px;
	min-width: 10rem;
	p {
margin-top: 17px;
		padding-right: 53px !important;
	}
}
