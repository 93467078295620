.tcardbg {
	background: #ffffff;
	box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04) !important;
	border-radius: 16px;
	min-height: 310px;
	margin-top: -20px;
}
.tcard {
	.message {
		font-size: 14px;
		line-height: 19.6px;
		margin-top: 22px;
	}
	.position {
		font-size: 14px;
		line-height: 22px;
		margin-top: -10px;
	}
	h5 {
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		margin-top: 15px;
	}

	.tcardphoto {
		width: 100%;
		border-radius: 50%;
		
	}

	@media screen and (min-width:300px) and (max-width:767px) {
		.tcard-info {
			display: flex;
			flex-direction: row;
			align-items: center;
			column-gap: 20px;
		}
	}


}
