.cardxlinner {
	h5 {
		font-weight: bold;
		font-size: 18px;
		line-height: 22px;
		color: #1f1f30;
	}
	p {
		font-weight: normal;
		font-size: 18px;
		line-height: 29px;
		margin-bottom: 40px;
	}
	.cardxlinner-titlesection {
		display: flex;
		column-gap: 20px;
		img {
			width: 26.67px;
			height: 26.67px;
			margin: 0 !important;
		}
	}
}
