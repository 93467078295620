/* .process h1 {
      margin-left: 65px !important;
    } */
/* .hdash:last-child {
  display: none !important;
} */
/* .process h1 {

    margin-top: 0px !important;
  } */
.process {
	h1 {
		font-weight: bold;
		font-size: 120px;
		line-height: 120px;
		display: flex;
		align-items: center;
		color: #ddddf0;
	}
	.process-number {
		color: #b1baff;

		&:hover {
			color: #41415a !important;
		}
	}
	p {
		font-weight: normal;
		font-size: 16px;
		line-height: 22.4px;
		text-align: center;
	}
}
.hdash {
	display: block;
}
.vdash {
	display: none;
}
// @media screen and (max-width: 767px) {
// 	.process {
// 		h1 {
// 			font-size: 70px !important;
// 			display: flex;
// 		}
// 	}
// }
@media screen and (min-width: 768px) {
	.process {
		.processThree1 {
			display: none;
		}
		.processThree2 {
			display: block;
		}

		.processFour1 {
			display: none;
		}

		.processFour2 {
			display: block;
		}

		h1 {
			font-weight: bold;
			font-size: 60px;
			line-height: 60px;
			display: flex;
			flex-direction: row;
			align-items: center;
			color: #ddddf0;
			justify-content: center;
		}

		p {
			text-align: center;
			width: 80%;
			margin: auto;
		}
	}
}

@media screen and (max-width: 500px) {
	.process {
		p {
			padding-top: 0px !important;
		}
	}
}

@media screen and (min-width: 0px) and(max-width:409px) {
	.process {
		margin: auto;
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
}
@media screen and (min-width: 410px) and (max-width: 499px) {
	.process {
		margin: auto;
		padding-left: 20px !important;
		padding-right: 20px !important;
	}
}
@media screen and (max-width: 767px) {
	.process {
		display: flex;
		justify-content: flex-start;
		column-gap: 50px;
		align-items: flex-start;
		margin: auto;
		padding-left: 50px;
		padding-right: 50px;

		.process-container {
			margin: auto;
			padding-left: 50px;
			padding-right: 50px;
		}

		.processThree1 {
			display: none;
		}
		.processThree2 {
			display: block;
		}

		.processFour1 {
			display: none;
		}
		.processFour2 {
			display: block;
		}
		h1 {
			font-weight: bold;
			font-size: 48px !important;
			line-height: 48px;
			display: flex;
			flex-direction: column;
			// align-items: center;
			color: #ddddf0;
			// float: left;
			// margin-top: 10px !important;
		}
		p {
			font-weight: normal;
			// font-size: 14px;
			// line-height: 21px;
			color: #41415a;
			text-align: left;
			// margin-left: 40px;
			padding-top: 10px;

			// padding-left: 80px !important;
		}
	}
	.hdash {
		display: none;
	}
	.vdash {
		display: block;
	}
}

@media screen and (max-width: 767px) {
	.process {
		display: flex;
		justify-content: flex-start;
		column-gap: 50px;
		align-items: flex-start;
		// padding-left: 150px;
		margin: auto;
		// padding-right: 150px;

		.process-container {
			margin: auto;
			// 		padding-left: 150px;
			// padding-right: 150px;
		}

		.processThree1 {
			display: none;
		}
		.processThree2 {
			display: block;
		}

		.processFour1 {
			display: none;
		}
		.processFour2 {
			display: block;
		}
		h1 {
			font-weight: bold;
			font-size: 48px !important;
			line-height: 48px;
			display: flex;
			flex-direction: column;
			// align-items: center;
			color: #ddddf0;
			// float: left;
			// margin-top: 10px !important;
		}
		p {
			font-weight: normal;
			// font-size: 14px;
			// line-height: 21px;
			color: #41415a;
			text-align: left;
			// margin-left: 40px;
			padding-top: 4px;

			// padding-left: 80px !important;
		}
	}
	.hdash {
		display: none;
	}
	.vdash {
		display: block;
	}
}
