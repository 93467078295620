:root {
	scroll-behavior: unset !important;
	--primary: #5260fd;
	--primary-tint: #f5f6ff;
	--heading-color: #41415a;
	--black-tertiary: #71748c;
}
* {
	font-family: "Helvetica Neue", sans-serif;
	font-style: normal;
}
span {
	color: #41415a;
}
ul {
	color: #41415a;
}
li {
	color: #41415a;
}
html {
	scroll-behavior: unset !important;
}
body {
	margin: 0;
	font-family: "Helvetica Neue", sans-serif;
	font-style: normal;
	font-weight: normal;
	color: #1f1f30 !important;
	scroll-behavior: unset !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	/* font-family: "Helvetica Neue 65 Medium", sans-serif !important; */
	font-style: normal;
	font-weight: normal;
	color: #1f1f30;
}

/* h2 {
  font-size: 36px;
  line-height: 50.4px;
}

p {
  font-size: 16px;
  line-height: 22.4px;
} */

input:focus {
	box-shadow: none !important;
	border: 2px solid #5260fd !important;
}

textarea:focus {
	box-shadow: none !important;
	border: 2px solid #5260fd !important;
}

p {
	color: #41415a !important;
	font-family: "Helvetica Neue", sans-serif !important;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22.4px;
}

.navTitle {
	font-family: "Helvetica Neue", sans-serif !important;

	font-size: 20px;
	margin-left: 15px;
	vertical-align: middle;
	margin-top: 4px;
}
.dropdown-icon {
	position: absolute;
	top: 10px;
	right: 0px;
}
.navigation .navbar-brand {
	display: flex;
	align-items: center;
}
.navigation .nav-item {
	padding-right: 15px;
}

.primary-tint-bg {
	background-color: var(--primary-tint);
}
.solutions .hero {
	padding-top: 0;
}

.startup-page.solutions.industry .smart-brands img {
	/* min-width: 170px; */
}
.hero-main-light,
#root .hero {
	background-color: transparent !important;
	min-height: 60vh;
}

.smart-splitters .splitter {
	display: flex;
	flex-direction: column;
	padding-bottom: 62px;
	padding-top: 62px;
	justify-content: space-between;
}

.smart-splitters .splitter img {
	max-width: 540px;
}

.smart-splitters .splitter > div {
	flex-basis: 45%;
	display: flex;
	align-items: center;
}

.smart-splitters .splitter div .section-ten p {
	color: var(--black-tertiary) !important;
}

.slider-btn {
	background-color: #fff;
	width: 50px !important;
	height: 50px;
	border-radius: 100%;

	display: none !important;
	align-items: center;
	justify-content: center;
	position: absolute;
	display: none;
	top: 50%;
}

.slider-btn-right {
	right: -10px;
}
.slider-btn-left {
	left: -10px;
}
.hero-action-list {
	display: flex;
	flex-direction: column;
}

.hero-action-list a {
	margin-bottom: 15px !important;
}

.hero-action-list a i {
	margin-left: 10px;
}

.hero-action-list a.btn-ghost {
	border: 1px solid var(--primary) !important;
}

.btn-transparent {
	display: inline-block;
	margin: 10px 0;
	color: var(--primary) !important;
}

.btn-transparent i {
	margin: 0 15px;
}

.transfer-banner .text-container .btn i,
.btn-transparent i,
.btn-ghost i {
	transition: 0.6s;
}

.btn-transparent:hover i,
.transfer-banner .text-container .btn:hover i,
.btn-ghost:hover i {
	transform: translate(10px);
}
.navigation .dropdown-item:active {
	color: inherit;
}

body .fab:first {
	padding-left: 0;
}
body .fab {
	border: none;
	color: var(--primary);
}
body .fab:hover {
	color: var(--heading-color);
}

ul.feature-list {
	list-style: none;
	padding: 0;
}

section {
	margin: 100px 0 !important;
}
ul.feature-list li {
	color: var(--primary);
	display: flex;
	align-items: baseline;
	margin-bottom: 10px;
}
ul.feature-list li p {
	margin: 0;
	margin-left: 10px;
	width: 70%;
}
.smart-section.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.smart-section.padded {
	padding: 50px;
}
.smart-section h2,
.hero h1 {
	font-weight: 700;
	font-size: 28px;
	line-height: 36px;
	text-align: center;
}

.hero .herodescription {
	text-align: center;
}
.smart-banner {
	display: grid;
	border-radius: 20px;

	overflow: hidden;
	background-color: #f5f6ff;
}

.smart-banner .btn {
	background-color: #fff;
	color: var(--primary);
	padding: 10px 25px;
	margin: auto;
	width: 100%;
}

.smart-banner .btn:hover {
	color: var(--primary);
	opacity: 0.9;
}
.smart-banner .banner-image {
	display: flex;
	justify-content: center;
	align-items: flex-end;
}
.smart-banner .banner-image img {
	width: 100%;
	height: auto;
}

.smart-banner .banner {
	background-color: var(--primary);
	padding: 50px;
	color: #fff !important;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.smart-banner .banner h3,
.smart-banner .banner p {
	color: #fff !important;
	margin-bottom: 25px;
	text-align: center;
	width: 100%;
}
.smart-banner .banner h3 {
	font-weight: 500;
}

.smart-banner .banner p {
	font-weight: 400;
}
.smart-brands {
	display: grid;
	grid-template-columns: repeat(2, 1fr);

	gap: 50px;
	justify-content: center;
	padding-top: 25px;
	padding-bottom: 25px;
	align-items: center;
}

.smart-brands .brand-image {
	display: flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
}

.smart-brands .slick-track {
	align-items: baseline;
	justify-content: center;
	display: flex;
}
.smart-brands img {
	max-width: 120px;
	height: auto;
	margin: auto;
}
.smart-card {
	font-family: "Helvetica Neue", sans-serif !important;

	border-radius: 20px;
	padding: 20px 40px;
	min-height: 335px;
	display: flex;
}
.smart-card-container {
	margin-top: 20px;
}

.smart-card.smart-card-type-image .smart-card-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.smart-card.smart-shadow,
.navigation .dropdown-menu {
	box-shadow: 0px 5px 5px 0px #2e3a9e14 !important;
	border: 1px solid #f6f6f8;
}

.smart-card.smart-center * {
	text-align: center;
}
.smart-card img.smart-card-icon {
	border-radius: 20px;
}

.smart-card .smart-card-image {
	width: 100% !important;
	height: auto;
}

.smart-card .smart-card-title {
	font-weight: 500;
	text-transform: capitalize;
	margin-bottom: 18px;
}

.smart-card .smart-card-subtitle {
	font-weight: 400;
	font-size: 16px;
	color: #71748c !important;
}
.appstore-link {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	margin-top: 25px;
}

.appstore-link a {
	margin-right: 10px;
}
.appstore-link img {
	margin: 0 !important;
	width: 150px !important;
}

.developer-page.solutions h3.mobile-header2 {
	/* text-align: center; */
	font-size: 18px;
}
ul.feature-list li {
	font-size: 16px;
	/* justify-content: center; */
}

.cardmd .cardmd-container {
	flex-direction: column;
}

.home-page .home-brand .container {
	max-width: 100%;
}

.home-page .home-brand .smart-brands {
	display: block;
}

@media screen and (min-width: 480px) {
}

@media screen and (min-width: 760px) {
	.slider-btn {
		display: flex !important;
	}

	.smart-splitters .splitter {
		flex-direction: row;
	}

	.smart-splitters .splitter div .section10Box1 {
		width: 90%;
	}

	.smart-splitters div:nth-child(2n) .splitter {
		flex-direction: row-reverse;
	}

	.smart-banner .banner {
		padding: 50px;
	}
	.cardmd .cardmd-container {
		flex-direction: row;
	}
	.appstore-link img {
		margin: 0 !important;
		width: 250px !important;
	}
	ul.feature-list li {
		justify-content: flex-start;
	}

	.developer-page.solutions h3.mobile-header2 {
		text-align: left;
	}
}

.promotion-dot {
	width: 10px;
	height: 10px;
	background: #e3e3e8;
	border-radius: 100%;
}

.slick-dots li {
	margin: 0;
}
.slick-dots li.slick-active .promotion-dot {
	background-color: var(--primary);
}

.home-head {
	line-height: 40px;
	font-size: 36px;
	margin-bottom: 20px;
}

.home-description {
	line-height: 24px;
	font-size: 18px;
}

@media screen and (min-width: 991px) {
	.smart-banner .banner > div {
		width: 80%;
	}

	.home-page .home-brand .smart-brands {
		display: block;
	}
	.home-page .home-brand {
		margin-top: -50px !important;
		margin-bottom: -50px !important;
	}
	.home-head {
		line-height: 72px !important;
		font-size: 60px !important;
		margin-bottom: 20px;
	}

	.home-description {
		line-height: 34px !important;
		font-size: 24px !important;
	}
	.developer-page.solutions h3.mobile-header2 {
		/* text-align: center; */
		font-size: 30px;
	}
	ul.feature-list li {
		font-size: 16px;
		font-size: initial;
		/* justify-content: center; */
	}
	.smart-section h2,
	.hero h1 {
		font-size: 36px;
		line-height: 44px;
		text-align: left;
	}

	.hero .herodescription {
		text-align: left;
	}
	.appstore-link {
		justify-content: flex-start;
	}
	.appstore-link a {
		margin-right: 25px;
	}
	.hero-action-list {
		flex-direction: row;
	}

	.smart-banner .banner-image img {
		width: 100%;
		height: auto;
	}
	.hero-action-list a.btn-ghost {
		border: none !important;
	}

	.appstore-link img {
		margin: 0 !important;
		width: auto !important;
	}
	.slider-btn-right {
		right: -50px;
	}
	.slider-btn-left {
		left: -50px;
	}
	.smart-card img.smart-card-icon {
		width: 80px !important;
		height: 80px !important;
	}
	.smart-brands {
		grid-template-columns: repeat(4, 1fr);
		justify-content: space-between;
	}
	.home-page .smart-brands {
		grid-template-columns: repeat(5, 1fr);
		justify-content: space-between;
	}

	.smart-banner .btn {
		margin: 0;
		width: auto;
	}
	.smart-brands img {
		/* max-width: 150px; */
	}

	.smart-banner .banner h3,
	.smart-banner .banner p {
		color: #fff !important;
		margin-bottom: 25px;
		text-align: left;
	}
	.smart-banner {
		grid-template-columns: 65% auto;
	}
}

/* .btn-ghost,
.btn-ghost:hover {
	background-color: transparent;
	border: none;
}
.btn-ghost:hover {
	opacity: 0.7;
} */
div#navbarSupportedContent {
	justify-content: end;
}
button:focus {
	box-shadow: none !important;
}

.btn-close:focus {
	box-shadow: none !important;
}

@media screen and (max-width: 991px) {
	/* .mobile-header1  {
    font-size: 30px !important;
    line-height: 42px !important;
  } */
}

@media screen and (min-width: 991px) {
	.navTitle {
		font-size: 35px;
	}
}

@media screen and (max-width: 767px) {
	/* h1 {

    font-size: 36px !important;
    line-height: 40px !important;
  }

  h2 {
    font-size: 27px !important;
    line-height: 36px !important;
  }

  h3 {
    font-size: 20px !important;
  }
  p {
    font-size: 16px !important;
    line-height: 22.4px !important;
  } */

	/* .accordion-body {
    font-size: 16px !important;
    line-height: 22.4px !important;
  } */

	/* .hero-main span {
    font-size: 16px !important;
    line-height: 22.4px !important;
  } */

	/* h1, h2 {
    font-size: 36px !important;
    line-height: 43px !important;
  } */

	/* .mobile-header {
    font-size: 30px !important;
    line-height: 42px !important;
  }


  .mobile-header1 {
font-weight: bold !important;
font-size: 24px !important;
line-height: 35px !important;
  }

  .mobile-header2 {
    font-weight: bold !important;
font-size: 24px !important;
line-height: 29px !important;
  }

  .mobile-header3 {
    font-weight: bold !important;
font-size: 18px !important;
line-height: 22px !important;
  }

    .mobile-header4 {
    font-size: 30px !important;
    line-height: 42px !important;
  } */

	.animate__fadeInUp {
		-webkit-animation-name: none !important;
		animation-name: none !important;
		animation-duration: unset !important;
	}

	.animate__zoomIn {
		-webkit-animation-name: none !important;
		animation-name: none !important;
		animation-duration: unset !important;
	}

	.animated {
		/*CSS transitions*/
		-o-transition-property: none !important;
		-moz-transition-property: none !important;
		-ms-transition-property: none !important;
		-webkit-transition-property: none !important;
		transition-property: none !important;
		/*CSS transforms*/
		-o-transform: none !important;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;
		/*CSS animations*/
		-webkit-animation: none !important;
		-moz-animation: none !important;
		-o-animation: none !important;
		-ms-animation: none !important;
		animation: none !important;
		opacity: 1 !important;
	}

	.img-hover-zoom img {
		transition: none !important;
	}

	.img-hover-zoom img:hover {
		transform: none !important;
	}

	.cardbg:hover .cards {
		transform: none !important;
	}

	.cardmd img {
		transition: none !important;
	}

	.cardmd img:hover {
		transform: none !important;
	}

	.contact-info {
		transition: none !important;
	}

	.section-three .learnmore-text {
		transition: none !important;
	}

	.section-three .learnmore-text:hover {
		transform: none !important;
	}

	.fab {
		transition: none !important;
	}

	.fab:hover {
		transform: none !important;
	}

	.section-eleven img {
		transition: none !important;
	}

	.section-eleven img:hover {
		transform: none !important;
	}
	.contact-info:hover {
		transform: none !important;
	}

	.section-ten img {
		transform: none !important;
	}
	.section-ten img:hover {
		transform: none !important;
	}

	.card-sm .maxtrusticon1 {
		transition: none !important;
	}

	.maxtrusticon1:hover {
		transition: none !important;
		transform: none !important;
	}

	.card-sm .maxtrusticon {
		transition: none !important;
	}

	.maxtrusticon:hover {
		transition: none !important;
		transform: none !important;
	}

	.cardbg .learnmore-text {
		transition: none !important;
	}

	.cardbg .learnmore-text:hover {
		margin-left: 0px !important;
	}
}

@media screen and (max-width: 575px) {
	/* .mobile-header4 {
    font-weight: bold !important;
font-size: 24px !important;
line-height: 29px !important;
  } */
}
@media screen and (min-width: 300px) and (max-width: 767px) {
	.container {
		padding-left: 30px !important;
		padding-right: 30px !important;
	}

	.container.no-padding {
		padding: 0;
	}
}

@media screen and (max-width: 400px) {
	/* p {
    font-size: 14px !important;
    line-height: 20.0px !important;
  } */

	/* .hero-main span {
    font-size: 14px !important;
    line-height: 20.0px !important;
  } */

	/* h1, h2 {
    font-size: 22px !important;
    line-height: 30px !important;
  } */

	/* .mobile-header1 {
font-weight: bold !important;
font-size: 18px !important;
line-height: 25px !important;
  }

  .mobile-header2 {
    font-weight: bold !important;
font-size: 18px !important;
line-height: 26px !important;
  }

  .mobile-header3 {
    font-weight: bold !important;
font-size: 18px !important;
line-height: 20px !important;
  } */

	/* .container {
    padding-left:30px !important;
    padding-right: 30px !important;
  } */
}

.psmall {
	font-weight: normal !important;
	font-size: 12px !important;
	line-height: 18px !important;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

html,
body {
	max-width: 100%;
	overflow: auto;
}

a:not(.btn) {
	text-decoration: none;
	color: #41415a;
}

a:hover {
	color: #000;
}

.navigation-shadow {
	box-shadow: 0 0.3px 0.3px rgb(0 0 0 / 0.1);
	background: #fff !important;
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
	padding-top: 0;
	padding-bottom: 0 !important;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
	opacity: 1 !important;
	transform: scale(0.85) translateY(-1.75rem) translateX(0.5rem) !important;
}

.activeLink {
	color: #5260fd !important;
	border-bottom: 3px solid #5260fd !important;
}

.activeLinkMobile p {
	color: #5260fd !important;
}

.activeDropdownLink {
	background-color: transparent !important;
	border-radius: 10px;
}

.disable-pointer {
	pointer-events: none !important;
}

.spanFormat span {
	color: #5260fd;
}
