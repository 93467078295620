.contact-info {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	background: #f5f6ff !important;
	border-radius: 8px;
	margin-bottom: 20px !important;
	width: 70%;
	float: left;
	margin: auto;
	-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	&:after {
		content: "";
		z-index: -1;
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
		opacity: 0;
		-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
		transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	}
	&:hover {
		-webkit-transform: scale(1.05, 1.05);
		transform: scale(1.05, 1.05);
		&::after {
			opacity: 1;
		}
	}
	span {
		font-size: 18px;
		line-height: 21px;
	}
	img {
		margin-right: -10px;
	}
	p {
		margin-top: 15px;
	}
}
@media screen and (max-width: 991px) {
	.contact-info {
		width: 100%;
		margin: auto;
	}
}
