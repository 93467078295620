.footer2 {
	padding-left: 120px;
	padding-right: 120px;
	padding-bottom: 50px;
	.social {
		.fab {
			font-size: 15px;
			color: #070715;
			cursor: pointer;
			margin-right: 15px;
			border-radius: 100%;
			padding: 10px;
			background-color: #fff;
		}
	}
}
.footer2section-one {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.footerlogo2 {
	p {
		margin-top: 10px;
		color: #fff;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
	}
}
.footer2copyright {
	font-family: Helvetica;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 18px;
	color: #ffffff;
	img {
		width: 35px;
		margin-bottom: 20px;
	}
}
.footer2section-two {
	display: none;
}
@media screen and (max-width: 1024px) {
	.footer2 {
		padding-left: 20px;
		padding-right: 20px;
		.social {
			.fab {
				cursor: pointer;
			}
		}
	}
	.footer2section-one {
		display: none;
	}
	.footer2section-two {
		display: block;
	}
	.footer2copyright {
		margin-bottom: 50px;
		margin-top: 40px;
	}
	.footer2section-two-logo {
		display: flex;
		justify-content: center;
		margin-bottom: 20px;
		flex-direction: column;
		align-items: center;
		p {
			margin-top: 10px;
			color: #fff;
			font-weight: normal;
			font-size: 14px;
			line-height: 18px;
		}
	}
	.footer2section-two-info {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
}
