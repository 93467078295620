.footer {
	background-color: #fff;
	border-top: 1px solid rgb(0 0 0 / 5%);
	.wrapper-inner {
		padding-top: 67px;
		padding-bottom: 40px;
	}

	// .countryname {
	// 	margin-top: 10px !important;
	// }

	.footerweb {
		ul li a:hover {
			font-weight: 500;
			color: inherit;
		}
	}

	.social {
		margin-left: -4px;
		img {
			margin-right: 10px;
		}
	}
	.footer-contacts {
		display: flex;
		flex-direction: row;
		justify-content: left;
		align-items: center;
		img {
			height: 17px;
			width: 17px;
			margin-right: 10px;
		}
	}
	h2 {
		font-size: 20px;
		line-height: 28px;
		margin-top: 49px;
		margin-bottom: 21px;
	}
	h3 {
		font-weight: bolder;
		font-size: 16px;
		line-height: 26px;
	}
	p {
		font-weight: normal;
		font-size: 12px;
		line-height: 16.8px;
		margin-top: 3px;
	}
	ul {
		padding-left: 0;
		li {
			font-weight: normal;
			font-size: 16px;
			line-height: 22.4px;
			list-style: none;
			margin-bottom: 20px;
			a {
				cursor: pointer;
			}
		}
	}

	.accordion {
		padding-bottom: 50px !important;
	}
	.accordion-button {
		padding: 0 !important;
		margin-bottom: 10px;
		font-weight: 550;
		font-size: 16px;
	}

	.accordion-button {
		&:focus {
			z-index: 3;
			border-color: transparent;
			outline: 0;
			box-shadow: none;
		}
		&::after {
			width: 1rem;
			height: 1rem;
			background-size: 1rem;
		}
	}
	// .accordion-item {
	// 	    margin-bottom: -30px;
	// }

	.accordion-header {
		font-weight: bold;
		margin-top: 0px;
		margin-bottom: 0px;
	}
	.accordion-button:not(.collapsed) {
		color: #41415a !important;
		background-color: #fff !important;
		border: none !important;
		box-shadow: none;
	}

	.accordion-body {
		padding-left: 0px;
		// padding-top: 10px;
		// padding-bottom: 10px;
		margin-bottom: 0px;
		margin-top: 0px;
	}

	// .accordion-header {
	// 	margin-bottom: -30px;
	// }
}
.home-landing {
	.footer {
		.social {
			.fab {
				color: #5260fd !important;
			}
		}
	}
}
@media screen and (max-width: 1199px) {
	.footer {
		.social {
			img {
				width: 22px;
			}
		}
	}
}
@media screen and (min-width: 992px) {
	.footer {
		.social-mobile {
			display: none !important;
		}
	}
}

@media screen and (min-width: 380px) {
	.footerweb {
		display: inherit;
	}

	.footermobile {
		display: none;
	}
}

@media screen and (max-width: 991px) {
	.footerweb {
		display: none !important;
	}

	.footermobile {
		display: block;
	}

	.footer h2 {
		width: 100%;
	}
}
@media screen and (max-width: 991px) and (min-width: 768px) {
	.footer {
		.wrapper-inner {
			padding-bottom: 0px;
		}
		.social-mobile {
			display: block !important;
			// text-align: left !important;
			margin-bottom: 50px;
		}
		.social-web {
			display: block;
		}
	}
}
@media screen and (max-width: 767px) {
	.footer {
		.wrapper-inner {
			padding-bottom: 0px;
		}

		.footer-mobile {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			column-gap: 35px;
		}

		.app-mobile {
			text-align: center;
			margin-top: -20px;
		}

		// .psmall {
		// 	max-width: 80%;
		// }

		.footerdescription {
			display: none !important;
		}

		.footer-app-button {
			margin-top: 30px;
		}

		h2 {
			font-size: 24px;
			line-height: 35px;
		}
		.social {
			.fab {
				font-size: 20px;
			}
			display: none;
		}
		.social-web {
			display: none !important;
		}
		.social-mobile {
			display: block;
			margin-bottom: 50px;
			margin-left: 0px;
			img {
				margin-right: 0px !important;
			}
			.fab {
				margin-right: 15px;
			}
		}

		.footerweb .footerwebrow {
			flex-flow: row-reverse;
		}

		.footertoggle2 {
			display: block;
		}

		.footertoggle1 {
			display: none;
		}
	}
}

@media screen and (min-width: 768px) and(max-width: 991px) {
	.footer {
		.countryselect {
			margin-left: -70px;
		}
		.footer-mobile {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;

			// column-gap: 35px;
		}

		// .app-mobile {
		// 	text-align: center;
		// }

		.psmall {
			max-width: 80%;
		}

		.footerdescription {
			display: none !important;
		}

		// .footer-app-button {
		// 	margin-top: 30px;
		// }

		h2 {
			font-size: 24px;
			line-height: 35px;
		}
		.social {
			.fab {
				font-size: 20px;
			}
			display: none;
		}
		.social-web {
			display: none !important;
		}
		.social-mobile {
			display: block;
			// margin-top: 50px;
			img {
				margin-right: 0px !important;
			}
			.fab {
				margin-right: 15px;
			}
		}

		.footerweb .footerwebrow {
			flex-flow: row-reverse;
		}

		.footertoggle2 {
			display: block;
		}

		.footertoggle1 {
			display: none;
		}
	}
}

@media screen and (min-width: 768px) {
	.psmall {
		max-width: 50%;
	}
	.footertoggle2 {
		display: none;
	}
}

@media screen and (min-width: 380px) and (max-width: 767px) {
	.psmall {
		max-width: 80%;
	}
}

@media screen and (max-width: 565px) {
	.footer {
		.footer-mobile {
			// 		display: flex;
			// justify-content: flex-start;
			// align-items: center;
			column-gap: 0px;
		}
	}
}

@media screen and (max-width: 330px) {
	.footer {
		.footer-mobile {
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			// align-items: center;
			column-gap: 0px;
		}
	}
}

@media screen and (max-width: 991px) {
	.footer {
		.footer-mobile {
			.footer-mobile-logo {
				img {
					width: 100px;
				}
			}
		}
	}
}
