.section-six {
	// padding: 80px 0px;
	// margin-top: 100px;

	// margin-bottom: 120px;

	// .section-six-wrapper {
	// 	display: none;
	// }

	@media screen and (max-width: 767px) {
		// margin-bottom: 180px;
		// display: none;
	}
	h2 {
		font-weight: bold;
		font-size: 36px;
		line-height: 44px;
		text-align: center;
		margin-bottom: 74px;
	}

	.slick-center {
		transition: all 0.1s ease;
		transform: scale(1.19);
	}

	.review-carousel {
		.review-item {
			position: relative;
			img {
				display: block;
				width: 50px !important;
				border-radius: 50%;
				box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
			}
			.tcard {
				padding: 37px 25px 0px 25px;
				background: #ffffff;
				// box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
				// border-radius: 16px;
				margin-top: 50px;
				margin-bottom: 50px;
				margin: 25px;
				min-height: 250px;
				transition: all 0.1s ease;
				transform: scale(1);
			}
		}
	}

	@media screen and (min-width: 1200px) {
		.review-carousel {
			.review-item {
				.tcard {
					margin: 5px !important;
					min-height: 20rem;
				}
			}
		}
	}

	@media screen and (max-width: 1199px) {
		.review-carousel {
			.review-item {
				.tcard {
					margin: 10px !important;
					min-height: 18rem !important;
				}
			}
		}
	}

	@media screen and (max-width: 767px) {
		h2 {
			font-size: 18px;
			line-height: 22px;
			padding-left: 5px;
			padding-right: 5px;
		}
		.review-carousel {
			.review-item {
				.tcard {
					margin: 5px !important;
					min-height: 20rem;
				}
			}
		}
	}

	@media screen and (max-width: 400px) {
		.review-carousel {
			.review-item {
				.tcard {
					margin: 5px !important;
					min-height: 22rem !important;
				}
			}
		}
	}

	@media screen and (max-width: 350px) {
		.review-carousel {
			.review-item {
				.tcard {
					margin: 5px !important;
					min-height: 25rem !important;
				}
			}
		}
	}
}

// @media screen and (max-width: 975px) {
// 	.section-six {
// 		.review-carousel {
// 			.review-item {
// 				img {
// 					display: block;
// 					width: 48.64px !important;
// 				}
// 			}
// 		}
// 	}
// }
// @media screen and (min-width: 1100px) {
// 	.section-six {
// 		.review-carousel {
// 			.review-item {
// 				.tcard {
// 					min-height: 250px !important;
// 				}
// 			}
// 		}
// 	}
// }
