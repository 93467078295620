@keyframes smoothScroll {
	0% {
		transform: translateY(-142px);
	}
	100% {
		transform: translateY(0px);
	}
}

.navigation {
	padding-top: 25px;

	// .activeMobileNav {
	// 	color: #5466F9 !important;
	// 	p {
	// 		color: #5466F9 !important;
	// 	}
	// }

	.navwrapper {
		position: relative;
	}

	.dropdown-item:focus,
	.dropdown-item:hover {
		background: #f5f6ff !important;
		border-radius: 10px !important;
	}

	.navbarmobile {
		ul {
			padding-left: 0;
			li {
				font-weight: normal;
				font-size: 16px;
				line-height: 22.4px;
				list-style: none;
				margin-bottom: 20px;
				a {
					cursor: pointer;
				}
			}
		}
		.accordion-item {
			// margin-bottom: -5px;
		}
		p {
			font-family: "Helvetica Neue 65 Medium", sans-serif !important;
			font-weight: 550;
			padding-top: 10px;
		}
	}
	.navigation {
		.nav-link {
			a {
				cursor: pointer;
			}
		}
	}

	.accordion {
		padding-top: 30px;
		padding-bottom: 10px !important;
	}
	.accordion-button {
		padding: 0 !important;
		margin-bottom: 25px;
		font-weight: 550;
		font-size: 16px;
		color: #41415a;
	}

	.accordion-button {
		&:focus {
			z-index: 3;
			border-color: transparent;
			outline: 0;
			box-shadow: none;
		}
		&::after {
			width: 1rem;
			height: 1rem;
			background-size: 1rem;
		}
	}

	.accordion-header {
		font-weight: bold;
		margin-top: 0px;
		margin-bottom: 0px;
	}
	.accordion-button:not(.collapsed) {
		color: #41415a !important;
		background-color: #fff !important;
		border: none !important;
		box-shadow: none;
	}

	.accordion-body {
		padding-left: 0px !important;
		// padding-top: 10px;
		// padding-bottom: 10px;
		margin-bottom: 0px;
		margin-top: 0px;
	}

	.supportcontactinfo img {
		-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

		transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
		width: 40px;
		height: 40px;
		&:after {
			content: "";
			z-index: -1;
			// box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
			opacity: 0;
			-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
			transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
		}
		&:hover {
			-webkit-transform: scale(1.1, 1.1);
			transform: scale(1.1, 1.1);
			&::after {
				opacity: 1;
			}
		}
	}

	.navbar-toggler {
		padding-top: 3px !important;
	}
	.navbar-toggler {
		&:focus {
			color: transparent !important;
		}
	}
	.nav-item.dropdown {
		&:hover {
			.dropdown-menu {
				display: block;
				margin-top: 0;
			}
		}
	}

	.supportdropdown {
		min-width: 31rem !important;
	}
	.dropdown-menu {
		position: absolute;
		z-index: 1000;
		min-width: 40rem;
		padding: 25px 28px;
		margin: 0;
		margin-top: 10px;
		font-size: 1rem;
		font-size: 16px;
		line-height: 19px;
		text-align: left;
		list-style: none;
		background: #ffffff !important;
		box-shadow: 0px 3px 20px #e2e2e2 !important;
		border-radius: 10px !important;
		border: none;
		b {
			font-weight: 600;
			font-size: 14px;
			line-height: 17px;
			margin-bottom: 10px;
			margin-top: 10px;
		}
		p {
			font-family: Helvetica;
			font-style: normal;
			font-weight: normal;
			font-size: 14px !important;
			line-height: 19.6px !important;
			color: #9d9db0;
		}
	}

	.dropdown-menu.small-submenu {
		min-width: 25rem;
	}
	.dropdown-item {
		display: flex;
		flex-direction: column;
		white-space: normal;
		&:hover {
			background: #f5f6ff;
			border-radius: 10px;
		}
	}
	.navbar-brand {
		// margin-top: 0px;
		margin-top: -8px;
		margin-right: 50px;
		width: 150px;

		img {
			width: 100%;
			height: auto;
		}
	}

	@media screen and (max-width: 991px) {
		.navbar-brand {
			margin-top: -2px;

			img {
				width: 100px;
			}
		}
	}
	.nav-item {
		margin-right: 35px;
	}
	.nav-link {
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		color: #1f2130 !important;
	}
	.coming-soon {
		font-weight: 500 !important;
		font-size: 10px !important;
		background: #f9f4ee !important;
		border-radius: 4px !important;
		color: #f07c11 !important;
		padding: 2px 8px;
	}
	.dropdown-item-flex {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		column-gap: 7px;
	}
	.text-transition_inner {
		div {
			position: relative !important;
		}
	}
	.h1-one {
		position: relative;
	}
}
.navbar-toggler {
	border: 0 !important;
	padding-left: 0px;
	padding-right: 0px;
	// padding: 0px;
	&:focus {
		outline: none !important;
		box-shadow: none !important;
		border: 0 !important;
	}
	&:active {
		outline: none !important;
		box-shadow: none !important;
		border: 0 !important;
	}
	.top-bar {
		transform: rotate(45deg);
		transform-origin: 10% 10%;
		width: 25px;
	}
	.middle-bar {
		opacity: 0;
		filter: alpha(opacity=0);
		width: 25px;
	}
	.bottom-bar {
		transform: rotate(-45deg);
		transform-origin: 10% 90%;
		width: 25px;
	}
}
.navbar-toggler-icon {
	&:focus {
		outline: none !important;
		box-shadow: none !important;
		border: 0 !important;
	}
}
// .dropdown-icon {
// 	color: rgba(0, 0, 0, 0.55);
// }
.toggler-icon {
	width: 30px;
	height: 3px;
	background-color: #41415a;
	display: block;
	transition: all 0.2s;
}
.middle-bar {
	margin: 4px auto;
}
.navbar-toggler.collapsed {
	.top-bar {
		transform: rotate(0);
		width: 25px;
	}
	.middle-bar {
		opacity: 1;
		filter: alpha(opacity=100);
		width: 25px;
	}
	.bottom-bar {
		transform: rotate(0);
		width: 25px;
	}
	.toggler-icon {
		background-color: #41415a;
	}
}

.navbar-light {
	.navbar-toggler {
		border: 0px !important;
		padding-top: 0;
	}
}

.fixed-top {
	transition: all 0.5s ease !important;
	animation: smoothScroll 1s forwards !important;
}
.supportdropdown {
	.support1 {
		background-color: #f2f6fb;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: default;
		.pbold {
			font-weight: 700;
			margin-bottom: 5px;
		}
		p {
			font-size: 20px !important;
			line-height: 23.2px !important;
			text-align: center;
		}
	}
	.support2 {
		cursor: default;
	}
}
.supportcontactinfo {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-radius: 8px;
	margin-bottom: 20px !important;
	width: 70%;
	margin: auto;
	p {
		padding-top: 10px;
	}
	img {
		// background-color: #F5F6FF;
		// padding-right: 5px;
		// width: 30px;
		// height: 30px;
		// border-radius: 15px;
		margin-right: 20px;
		margin-left: -20px;
	}
}
@media screen and (min-width: 992px) {
	.webnavbar {
		display: block;
	}
	.mobilenavbar {
		display: none;
	}
}
@media screen and (max-width: 767px) and (min-width: 520px) {
	.mobilenavbar {
		.mobilenavbaritem {
			max-width: 70%;
		}
	}
}
@media screen and (min-width: 768px) and (max-width: 991px) {
	.mobilenavbar {
		.mobilenavbaritem {
			max-width: 50%;
		}
	}
}

@media screen and (max-width: 567px) {
	.mobilenavbaritem {
		flex-direction: column !important;
	}
}
@media screen and (max-width: 991px) {
	.webnavbar {
		display: none !important;
	}

	.mobilenavbar {
		display: block;
		// margin-top: -10px;
		.mobilenavbaritem {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
		}
		.mobilenavbar-title-top {
			margin-top: 44px;
			font-family: "Helvetica 65 Medium", sans-serif !important;
			font-weight: 700 !important;
			font-size: 18px !important;
			line-height: 19px !important;
		}
		.mobilenavbar-title {
			margin-top: 22px;
			font-family: "Helvetica 65 Medium", sans-serif !important;
			font-weight: 700 !important;
			font-size: 18px !important;
			line-height: 20px !important;
		}
	}
	.navigation {
		.navbar-collapse {
			position: absolute;
			z-index: 105;
			background-color: #fff;
			box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
				0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
			border-radius: 8px;
			// left: 0;
			right: 2rem;
			top: 0;
			margin-top: 50px;
			padding-left: 40px;
			padding-right: 40px;
			margin-left: 40px;
			// margin-right: 150px;
			min-width: 50%;
		}
	}
	.mobilenavbar-login {
		color: #5466f9 !important;
		font-style: normal;
		font-weight: 500;
	}
	.mobilenavbar-button {
		margin-top: 30px;
		text-align: center;
	}
	.mobilenavbaritem {
		p {
			font-weight: normal;
			font-size: 16px !important;
			line-height: 18px !important;
			color: #41415a;
		}
	}
}
@media screen and (min-width: 1022px) {
	.supportdropdown {
		margin-left: -300px !important;
		padding: 0 !important;
	}
}
@media screen and (max-width: 992px) {
	.navigation {
		.supportdropdown {
			display: none !important;
		}
	}
}
@media screen and (max-width: 767px) {
	.navigation {
		.dropdown-menu {
			position: absolute;
			z-index: 1000;
			min-width: 15rem !important;
		}

		.navbar-collapse {
			// margin-right: 150px;
			min-width: 70%;
		}
	}
}

@media screen and (max-width: 370px) {
	.navigation {
		.navbar-collapse {
			// margin-right: 150px;
			min-width: 80%;
		}
	}
}
@media screen and (max-width: 1200px) {
	.navigation {
		.nav-item {
			margin-right: 15px !important;
		}
		.nav-link {
			font-size: 14px !important;
			margin-top: 15px;
		}
	}
}
@media screen and (max-width: 300px) {
	.navbar-light {
		.navbar-toggler {
			border: 0px !important;
			width: 20px;
			height: 20px;
			margin-top: 0px;
			margin-right: 10px;

			.middle-bar {
				margin: 3.3px auto;
			}
		}
		.toggler-icon {
			width: 20px !important;
			height: 2px !important;
		}
	}
}

@media screen and (max-width: 1200px) {
	.navigation .nav-link {
		font-size: 16px !important;
		line-height: 19px !important;
		margin-top: 0px;
	}
}

.supportdropdown .support1 {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}
