.fab {
	@media screen and (min-width: 992px) and(max-width:1199px) {
		// font-size: 20px;

		margin-right: 8px;
		border-radius: 100%;
		padding: 5px 2px;
	}

	font-size: 18px;
	color: #070715;
	cursor: pointer;
	margin-right: 15px;
	border-radius: 100%;
	padding: 5px 2px;
	border-radius: 100%;
	border: 1px solid #5260fd;

	-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	&:after {
		content: "";
		z-index: -1;
		// box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
		opacity: 0;
		-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
		transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	}
	&:hover {
		-webkit-transform: scale(1.1, 1.1);
		transform: scale(1.1, 1.1);
		&::after {
			opacity: 1;
		}
	}
}
