.accordioncontainer {
	.accordion-item {
		border-right: 0;
		border-left: 0;
		margin-bottom: 15px;
		border: 1px solid #EAEAEA;
		border-top: 0px;
		border-left: 0px;
		border-right: 0;
		.accordion-button {
			color: #41415A !important;
			border: none;
			box-sizing: border-box;
			border-radius: 0;
			box-sizing: border-box;
			font-family: Helvetica;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 28px;
			padding-top: 25px !important;
			padding-bottom: 35px !important;
			border-top: none;
		}
		&:first-child {
			border: 1px solid #EAEAEA;
			border-top: 0px;
			border-left: 0px;
			border-right: 0;
		}
		&:last-child {
			border-bottom: 1px solid #EAEAEA;
		}
	}
	.accordion-button.collapsed {
	&::after {
		background-image: url("../assets/landingpages/+.svg");
		size: 2px;
	}
}

.accordion-button {
	&:not(.collapsed) {
		color: #41415A !important;
		background-color: #FFFFFF !important;
		box-shadow: none !important;
		border: none;
		&::after {
			background-image: url("../assets/landingpages/x.svg");
			transform: rotate(0deg) !important;
		}
	}
	&:focus {
		z-index: 3;
		color: #41415A !important;
		border-color: transparent;
		outline: 0;
		box-shadow: none;
	}
	&::after {
		width: 1rem;
		height: 1rem;
		background-size: 1rem;
	}
}
}
.accordion-body {
	color: #41415A !important;
	padding: 1rem 1.25rem;
	text-align: left;
	margin-top: -20px;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	margin-bottom: 20px;
	font-size: 16px;
	line-height: 22.4px;
}
.accordion-item {
	background-color: #fff;
	border: none;
	background-color: #fff;
	border: none;
}


.accordion-default {
	b {
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
	}
	button {
		font-weight: 500;
		font-size: 36px;
		line-height: 36px;
	}
}
@media screen and (max-width:992px) {
	.accordion-flush {
		.accordion-item {
			.accordion-button {
				padding-top: 10px !important;
				padding-bottom: 20px !important;
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.accordion-flush {
		.accordion-item {
			.accordion-button {
				font-size: 16px;
				line-height: 21px;
			}
		}
	}
}
