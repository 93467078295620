.hero {
	padding-top: 100px;
	position: relative;
	margin-bottom: 50px;

	.hero-image-container {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		justify-content: end;
		img {
			margin: 0;
			max-width: 700px;
			height: auto;
			margin-top: 40px;
			max-width: 90vh;
		}
	}
	img {
		width: 100%;
		max-width: 1000px;
		margin-left: 0px;
	}
	h1 {
		font-weight: bold;
		font-size: 48px;
		line-height: 55px;
	}
	p {
		font-weight: normal;
		font-size: 18px;
		line-height: 29px;
		margin-bottom: 20px;
	}

	.HeroMainDescription {
		span {
			color: #5260fd;
			// font-weight: lighter;
		}
	}

	.herodescription {
		margin-bottom: 13px !important;
		color: #41415a;
		max-width: 100%;
	}

	.HeromMainDescriptionRender {
		margin-bottom: 10px !important;
	}
}

@media screen and (max-width: 767px) {
	.hero {
		padding-top: 50px !important;
		margin-bottom: 0px !important;
		h1 {
			font-size: 36px;
			line-height: 40px;
			font-weight: bold;
		}

		p {
			font-weight: normal;
			font-size: 16px;
			line-height: 22.4px;
		}
	}
}

@media screen and (min-width: 992px) {
	.hero {
		img {
			margin-left: 40px;
		}
		.content-container {
			min-height: 85vh;
		}

		.herodescription {
			margin-bottom: 13px !important;
			color: #41415a;
			max-width: 80%;
		}
	}
}
@media screen and (max-width: 1199px) {
	.hero {
		padding-top: 100px;
		position: relative;
	}
}
@media screen and (max-width: 991px) {
	.heroImage {
		display: none;
	}

	.hero-featured {
		display: none;
	}
}

@media screen and (min-width: 400px) {
	.toggle1 {
		display: block;
	}

	.toggle2 {
		display: none;
	}
}

@media screen and (max-width: 399px) {
	.toggle1 {
		display: none;
	}

	.toggle2 {
		display: block;
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	.hero {
		padding-top: 50px !important;
		margin-bottom: 0px !important;
		h1 {
			font-size: 48px !important;
			line-height: 52px !important;
			font-weight: bold;
		}

		p {
			font-weight: normal;
			font-size: 16px !important;
			line-height: 22.4px;
		}
	}
}
