.section-five {
	background: #f5f6fa;
	padding: 70px 10px;
	h2 {
		font-weight: bold;
		font-size: 36px;
		line-height: 50.4px;
		text-align: center;
		margin-bottom: 55px;
	}

	.next-process {
		align-items: center;
		display: flex;
		justify-content: center;
	}
}

@media screen and (max-width: 767px) {
	.next-process {
		display: none !important;
	}
	.section-five {
		padding-bottom: 50px;
		padding-top: 50px;
		// margin-top: -70px !important;
		h2 {
			font-size: 27px;
			line-height: 36px;
		}
		.process-container {
			margin: auto;
		}
	}
}

@media screen and (min-width: 768px) {
	.process-container {
		display: flex;
		justify-content: center;
	}
	.process-content {
		display: flex;

		justify-content: flex-end;
	}
	.process-content .next-process {
		display: block !important;
	}
}
@media screen and (min-width: 768px) and (max-width: 991px) {
	.section-five {
		padding-bottom: 50px;
		padding-top: 50px;
		// margin-top: -70px !important;
		h2 {
			font-size: 30px;
			line-height: 39px;
		}
	}
}
