.support-container {
	.navigation {
		background-color: #fff !important;
	}
	.form-control {
		background-color: transparent;
		resize: none;
		border-color: #efefef;
		border-radius: 0.5rem;
	}

	.invalid {
		border: 1px solid #FF7F7F;
	}


	.support-intro {
		h1 {
			font-weight: bold;
			font-size: 64px;
			line-height: 78px;
			text-align: center;
			margin-top: 120px;
		}
		p {
			font-weight: normal;
			font-size: 20px;
			line-height: 32px;
			text-align: center;
			margin-top: 24px;
		}
	}
	.support-contact-form {
		span {
			background: #FDFDFD;
			padding-left: 10px;
			padding-right: 10px;
			color: #9d9db0 !important;
		}
		.form-errors {
			background: #F8ECF3;
			color: #C5046C;
			font-size: 16px;
			line-height: 19px;
			padding: 5px 10px;
			margin-top: -30px;
		}
		.form-success {
			background: #EBF7EE;
			color: #22B231;
			font-size: 16px;
			line-height: 19px;
			padding: 5px 10px;
		}
	}
	.support-contact {
		margin-top: 180px;
	}
	h4 {
		font-weight: 500;
		font-size: 24px;
		line-height: 34px;
	}
	.footer {
		.social {
			.fab {
				color: #5260fd !important;
			}
		}
	}
	.countryselect {
		.countryselectform {
			background-color: #f3f4ff;
			border: none;
			width: 20%;
			height: 20px;
			margin-top: 5px;
		}
	}
	.toast-progress {
		color: pink !important;
	}
}

@media screen and (min-width:992px) {
	.support-contactForm {
		width: 80%;
		margin: auto;
	}

	.supportFormInfo {
		width: 80%;
		margin: auto;
	}

}

@media screen and (max-width:767px){
.support-container {
	.support-intro {
		h1 {
			font-size: 36px;
			line-height: 40px;
			margin-top: 70px;
		}
	}
	h4 {
		font-size: 16px !important;
		line-height: 22.4px;
	}

	.supportdetails {
		margin-bottom: 0px;
	}
}
}
	
@media screen and (min-width:768px) and (max-width:991px) {
	.support-container {
		.support-intro {
			h1 {
				    font-size: 48px !important;
    line-height: 52px !important;
	margin-top: 70px;
			}
		}
	}
}
@media screen and (max-width:991px) {
	.support-container {
		.supportmobilereverse {
			flex-flow: column-reverse !important;
		}
		.supportdetails {
			margin-top: -100px;
			margin-bottom: 200px;
		}
		.supportform {
			margin-top: -100px;
		}

		.appbutton {
			    display: grid!important;
		}
	}

	.support-contactForm {
		width: 100%;
		margin: auto;
	}
}
@media screen and (max-width:991px) {
	.support-container {
		.form-control {
			width: 100% !important;
		}
	}
}
@media screen and (max-width: 767px) {
	.support-container {
		.countryselect {
			.countryselectform {
				width: 10px;
			}
		}
	}
}
@media screen and (max-width: 991px) {
	.support-container {
		h4 {
			font-size: 18px;
			line-height: 22.4px;
		}
	}
}
