

// @media screen and (min-width:992px) {

// 	.section-ten {
//     margin-top: 100px;
//     margin-bottom: 100px;

// }

// .featuredBoxBGCover1 {
	
//     // text-align: center;
// 	width: 45%;

// }


// .featuredBoxBGCover2 {
	
//     // text-align: center;
// 	width: 25%;

// }}

.section-ten {


	img {
		width: 100%;
		display: block;
		transition: transform 2.5s ease;
		&:hover {
			transform: scale(1.05);
		}
	}
	h3 {
		font-weight: bold;
	font-size: 30px;
    line-height: 42px;
	}
	p {
		font-weight: normal;
		font-size: 18px;
		line-height: 29px;
	}
	.imghoverzoom {
		overflow: hidden;
	}

	.customImageSize {
	width: 80% !important;
	margin: auto ;
}

// 	@media screen and (min-width:768px) and (max-width:991px) {
//   img {
//     width: 70% !important;
//     margin: auto;
//   }

//   .customSizeImage {
// 		width: 60% !important;
// 		margin: auto;
//   }
// }

	.customSizeImage {
		width: 70%;
		margin: auto;

		@media screen and (max-width:991px){
			margin: auto;
		}
	}
	.featuredBoxBG {
		background: #F5F6FF;
    border-radius: 10px;
    // text-align: center;
	padding: 30px;
}

.featuredBoxBGcircle {
	background-image: url("../../assets/landingpages/invoice/circleImageBg.svg");
	background-position: center; 
  background-repeat: no-repeat; 
  background-size: contain;
  padding: 30px;
}


   @media screen and (min-width:768px) {

	.section-ten-row {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.customPadding {
		padding-top: 80px;
		padding-bottom: 80px;
	}

    .bca-title3 {
		display: block;
	}

	.bca-title3-mobile {
		display: none;
	}
	   .section10Box1 {
		padding: 20px 0px 20px 0px;	
	}
	// 	.section10Box2 {
	// 	padding: 50px 20px;
	// }
	// 	.section10Box3 {
	// 	padding: 20px 0px 20px 0px;
	// 	}
	// // }
	// 	.section10Box4 {
	// 	padding: 10px 10px;
	// }


	.section10BoxCustom {
		padding: 20px 70px 20px 0px;
	}
	   
   }

    @media screen and (max-width:767px) {

			.featuredBoxBG {
		background: #F5F6FF;
    border-radius: 10px;
    // text-align: center;
	padding: 20px 10px 20px 10px;
}

	.bca-title3 {
		display: none;
	}

	.bca-title3-mobile {
		display:block;
	}

		 .colummnReverse {
	   flex-flow: column-reverse;
   }
	//    .section10Box1 {
	// 	padding: 50px 0px 80px 0px;
	// }
	
	// 	.section10Box3 {
	// 	padding: 50px 0px 80px 0px;
	// }

	// 	.section10Box2 {
	// 	padding: 50px 20px 50px 50px;
	// }

	// 	.section10Box4 {
	// 	padding: 50px 20px 50px 20px;
	// }

	// .section10BoxCustom {
	// 	padding: 0px 0px 80px 0px;
	//     .bca-title3-mobile {
	// 		margin-top: -35px;
	// 	}

	// 	.mobile-header2 {
	// 		margin-top: -35px;
	// 	}
	// }

	   .section10Box1 {
		padding: 40px 0px 80px 0px;
		// padding-bottom: 65px !important;
	}

	.section10Box2 {
		padding: 40px;
	}

	.section10Box4 {
		padding: 40px;
	}
	
		.section10Box3 {
		padding: 40px 0px 80px 0px;
		// padding-bottom: 65px !important;

	}

	
	   
   }

    @media screen and (max-width:767px) {
	.featuredBoxBG {
   
    padding: 20px 30px 20px 30px;
}
			h3 {
				font-size: 27px;
				line-height: 36px;
			}
		
	   .section10Box1 {
		padding: 25px 0px 50px 0px;
		// padding-bottom: 65px !important;
	}
	
		.section10Box3 {
		padding: 25px 0px 50px 0px;
		// padding-bottom: 65px !important;

	}

	.section10BoxCustom {
		padding: 0px 0px 50px 0px;
		

		// .bca-title3-mobile {
		// 	margin-top: -35px;
		// }

		// .mobile-header2 {
		// 	margin-top: -35px;
		// }
		
	}

	


		.section10Box2 {
		padding: 10px 0px;
	}

		.section10Box4 {
		padding: 10px 0px;
	}
	   
   }
	


}
