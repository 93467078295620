.cards-page {
	.section-five {
		background: #fff !important;
		padding: 0px !important;
		padding-bottom: 100px !important;
	}
	.subtitle-text {
		background: #F9F4EE !important;
		color: #F07C11 !important;
		padding: 8px 16px;
		font-weight: 500;
		font-size: 16px;
		line-height: 16px;
		width: fit-content;
		border-radius: 8px;
	}
	.hero {
		margin-bottom: 200px;
		// h2 {
		// 	font-weight: bold;
		// 	font-size: 48px;
		// 	line-height: 59px;
		// }
	}
	.section-eight {
		margin-top: 50px;
	}

	@media screen and (max-width:991px) {
		.section-eight {
		margin-top: 180px;
	}
	}
	.footer {
		.btn-default {
			&:hover {
				color: #fff;
			}
		}
		.social {
			.fab {
				color: #5260fd !important;
			}
		}
	}
	.countryselect {
		.countryselectform {
			background-color: #f3f4ff;
			border: none;
			width: 20%;
			height: 20px;
			margin-top: 5px;
		}
	}
}
@media screen and (max-width: 769px) {
	.cards-page {
		.countryselect {
			.countryselectform {
				width: 10px;
			}
		}
		
	}
}


@media screen and (max-width:991px) {
	.cards-page {
		.hero img {
			display: none !important;
		}

		.hero {
			margin-bottom: 100px;
		}
	}
}