.fees-container {
	.section-five {
		padding: 0px !important;
		padding-top: 80px !important;
		padding-bottom: 100px !important;
	}
	.navigation {
		background: #fff !important;
	}

	.tablecustomTD {
		text-align: right;
		font-weight: bold !important;

		span {
			font-size: 12px;
			font-weight: 400;
		}
	}

	.tablecustomTD1 {
		float: right;

		span {
			font-size: 12px;
			font-weight: 400;
		}
	}
	.fees-intro {
		h1 {
			font-weight: bold;
			font-size: 64px;
			line-height: 78px;
			margin-top: 100px;
		}
		p {
			font-size: 20px;
			line-height: 32px;
		}
	}
	.fees-table {
		margin-top: 150px;
		.table1 {
			table {
				td {
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					color: #1f1f30;
				}
			}

			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
				0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
			border-radius: 12px;
			padding-top: 70px;
			padding-bottom: 70px;
			padding-left: 40px;
			padding-right: 40px;
			min-height: 500px;
		}
	}
	.table {
		// width: 80%;
		margin: auto;
		min-height: 400px;
		> {
			:not(caption) {
				> * {
					> * {
						border-bottom-width: 0px;
					}
				}
			}
		}
	}
	.footer {
		.social {
			.fab {
				color: #5260fd !important;
			}
		}
	}
	.countryselect {
		.countryselectform {
			background-color: #f3f4ff;
			border: none;
			width: 20%;
			height: 20px;
			margin-top: 5px;
		}
	}
	.fees-table-header {
		font-weight: bold;
		font-size: 36px;
		line-height: 36px;
		padding-bottom: 24px !important;
		margin: auto;
	}
	.OOOImage {
		margin-top: 50px;
		img {
			width: 100%;
			margin: auto;
		}
	}

	.smalltextcontainer1 {
		position: relative;
	}

	.smalltext {
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		color: #139ab7 !important;
		background: #f2f6fb;
		border-radius: 8px;
		padding: 8px 16px;
		text-align: center !important;
		// top: 0;
		// right: 0;
	}
}
.table-pr {
	padding-right: 150px;
}
@media screen and (min-width: 992px) {
	.fees-container {
		.fees-table {
			.table1 {
				table {
					td {
						img {
							margin-top: -10px;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.fees-container {
		.fees-table {
			.table1 {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
		.countryselect {
			.countryselectform {
				width: 10px;
			}
		}
		.fees-intro {
			h1 {
				margin-top: 70px !important;
				font-size: 36px;
				line-height: 40px;
			}
			p {
				font-size: 16px;
				line-height: 22.4px;
			}
		}

		.fees-table-header {
			font-size: 27px;
			line-height: 40px;
		}
	}
}
@media screen and (max-width: 991px) {
	.fees-container {
		.fees-table {
			margin-top: 50px;
			margin-bottom: 100px;
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	.fees-container {
		.fees-intro {
			h1 {
				font-weight: bold;
				font-size: 48px;
				line-height: 52px;
				margin-top: 70px;
			}
			p {
				font-size: 18px;
				line-height: 25.5px;
			}
		}
	}
}
