.home-landing {
	.HeroMainDescription span {
		color: #5260fd !important;
		// font-weight:lighter !important;
	}

	.countryselect img {
		border: 2px solid rgba(0, 0, 0, 0.03);
	}
	@media screen and (max-width: 767px) {
		.hero-main-wrapper {
			margin-bottom: 100px !important;
		}
	}

	.countryselect {
		.countryselectform {
			background-color: #f3f4ff;
			border: none;
			width: 20%;
			height: 20px;
			margin-top: 5px;
		}
	}
}
@media screen and (max-width: 767px) {
	.home-landing {
		.countryselect {
			.countryselectform {
				width: 10px;
			}
		}

		.section-one {
			margin-bottom: 0;
		}
	}

	.home-page .home-image {
		display: none;
	}
}

@media screen and (min-width: 992px) {
	.home-landing {
		.section-five {
			.btn-hero-button {
				margin-bottom: 40px !important;
			}
		}
	}
}
