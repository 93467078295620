.transfer-banner {
	.container {
		background: linear-gradient(99.09deg, #5466f9 28.74%, #2e3a9e 79.68%);
	}

	.content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.text-container {
		flex-basis: 50%;
		padding: 50px;
		padding-bottom: 0px;

		h3 {
			font-size: 30px;
			font-weight: bold;
		}
		h3,
		p,
		li {
			color: #fff !important;
			width: 100%;
		}

		.btn {
			padding: 10px 30px;
			border-radius: 8px;
			margin: 20px 0;
			color: #5466f9;
			width: 100%;
			i {
				margin-left: 12px;
			}
		}
	}

	.image-container {
		flex-basis: 50%;
		display: flex;
		align-items: flex-end;

		img {
			max-width: 100%;
		}
	}
}

@media screen and (max-width: 767px) {
	.transfer-banner .image-container {
		margin-right: 6%;
	}
}
@media screen and (min-width: 576px) {
	.transfer-banner {
		.container {
			border-radius: 20px;
		}
	}
}
@media screen and (min-width: 992px) {
	.transfer-banner {
		.content {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: flex-end;
		}
		.text-container {
			padding: 70px 100px;

			h3 {
				font-size: 54px;
				margin-bottom: 20px;
			}

			p,
			li {
				font-size: 20px;
				font-weight: 400;
			}

			.btn {
				width: auto;
			}
		}
	}
}
