.subscribe-form {
	.form-control {
		background: transparent;
		border: 1px solid #cccccc;
		box-sizing: border-box;
		border-radius: 8px;
		position: relative;
		z-index: 1;
	}

	.invalid {
		border: 1px solid #FF7F7F;
	}
	.btn {
		margin-top: 5px !important;
		margin-bottom: 5px !important;
		border-radius: 8px !important;
		background: #7273db !important;
		&:hover {
			border-color: #6a6be7;
			background: #6a6be7 !important;
			color: #fff;
		}
	}
}
.form-control {
	&:focus {
		z-index: 1 !important;
	}
}
.subscribe-container {
	.form-errors {
		color: #F8ECF3 !important;
	}
}
@media screen and (max-width: 500px) {
	.subscribe-form {
		.btn {
			width: 70% !important;
		}
	}
}
@media screen and (max-width: 1000px) {
	.subscribe-form {
		.btn {
			border-radius: 8px !important;
			background: #7273db !important;
			position: absolute;
			top: 150%;
			right: 0;
			left: 0;
			margin-left: auto !important;
			margin-right: auto !important;
			width: 50%;
			padding: 14px 14px;
		}
		.form-control {
			padding: 20px;
		}
		width: 80% !important;
		margin: auto !important;
		border-radius: 8px !important;
	}
}
@media screen and (min-width: 1001px) {
	.subscribe-form {
		.btn {
			margin-left: -190px !important;
		}
		width: 45% !important;
		margin: auto !important;
		border-radius: 8px !important;
	}
}
