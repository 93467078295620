.section-twelve {
	padding: 0px 250px;
	h2 {
		font-weight: bold;
		font-size: 54px;
		line-height: 70px;
		text-align: center;
		color: #fff;
		margin-bottom: -40px;
	}
	p {
		font-weight: normal;
		font-size: 24px;
		line-height: 34px;
		text-align: center;
		color: #fff;
		margin-top: 80px;
		margin-bottom: 30px;
	}
}

@media screen and (min-width:1200px) {
	.section-twelve {
		width: 80%;
    margin: auto;
	}
}
@media screen and (max-width: 1199px) {
	.section-twelve {
		padding: 0px 0px;
		h2 {
			font-size: 30px !important;
			line-height: 36px !important;
			text-align: center;
			color: #fff !important;
			width: 98%;
			margin: auto;
			margin-top: 100px;
			margin-bottom: -60px !important;
		}
	}
}
@media screen and (max-width: 767px) {
	.section-twelve {
		padding: 0px 0px;
		h2 {
			font-weight: bold;
			font-size: 30px !important;
			line-height: 36px !important;
		}
		p {
			font-size: 16px !important;
		}
	}
}
