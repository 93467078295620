.billpayment-page {
	.section-five {
		background: #fff !important;
		padding: 0px !important;
		padding-bottom: 100px !important;
	}
	.footer {
		.social {
			.fab {
				color: #5260fd !important;
			}
		}
	}

	.section-ten .section10Box4 {
		padding: 0;
	}
	.descriptionBox {
		display: flex;
		column-gap: 20px;

		p {
			border-radius: 8px;
			padding: 8px 16px;
			font-weight: 500;
			font-size: 12px;
			line-height: 12px;
		}
	}
	.span1span1span1span1 {
		display: none;
	}
	.descriptionBox1 {
		background: #f9f4ee;
	}
	.descriptionBox2 {
		background: #ebf7ee;
	}
	.descriptionBox3 {
		background: #f8ecf3;
	}
	.descriptionBox4 {
		background: #ebf7ee;
	}
	.descriptionBox5 {
		background: #f2f6fb;
	}
	.hero {
		// background: linear-gradient(180deg, #F5F6FA 55.73%, rgba(243, 244, 255, 0) 100%) !important;
		padding-bottom: 50px;
		padding-top: 50px;
		// h2 {
		// 	font-weight: bold;
		// 	font-size: 48px;
		// 	line-height: 59px;
		// 	font-weight: bold;
		// 	font-size: 48px;
		// 	line-height: 59px;
		// }
		p {
			font-weight: normal;
			font-size: 20px;
			line-height: 32px;
		}
		img {
			width: 90%;
		}
	}
	// .section-ten-row-custom {
	// 	margin-top: -150px;
	// }
	.section-eight {
		margin-top: 100px;
		// padding-top: 150px;
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	.billpayment-page {
		.section-ten {
			.descriptionBox {
				display: flex;
				column-gap: 10px;
			}
		}
	}
}
@media screen and (min-width: 992px) {
	.billpayment-page {
		.side1style {
			margin-top: 50px;
		}
	}
}
@media screen and (max-width: 991px) {
	.billpayment-page {
		// .section-five {
		// 	margin-top: -100px;
		// }
		.section-eight {
			margin-top: -80px;
		}
		.hero {
			padding-bottom: 50px;
		}
		.hero {
			img {
				width: 80%;
				margin: auto;
				display: none;
			}
		}
		.custom10row {
			flex-direction: column-reverse !important;
			display: flex;
		}
		.section-ten-two {
			img {
				width: 100%;
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.billpayment-page {
		.hero {
			h2 {
				font-weight: bold;
				font-size: 38px;
				line-height: 40px;
			}
		}
	}
}

@media screen and (max-width: 499px) {
	.descriptionBoxToggle1 {
		display: none !important;
	}
	.descriptionBoxToggle2 {
		display: block;
		margin-top: 36px;
	}

	.descriptionBoxToggle2 p {
		padding: 4px 16px !important;
	}
}
@media screen and (min-width: 500px) {
	.descriptionBoxToggle1 {
		display: block;
	}
	.descriptionBoxToggle2 {
		display: none;
	}
}
