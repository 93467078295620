.hero-main-light {
	// background: linear-gradient( 180deg, #f5f6fa 55.73%, rgba(243, 244, 255, 0) 100% );
}
.hero-main-dark {
	background: #070715;
}
.hero-main-wrapper {
	.x1 {
		display: inline-block !important;
		width: auto !important;
	}
	.hero-subtext {
		font-weight: 500;
		font-size: 24px;
		line-height: 33.6px;
		margin-bottom: 16px;
	}
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.hero-main {
	.hero-typingText {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		column-gap: 11px;
		font-weight: bold;
		font-size: 64px;
		line-height: 70px;
		text-align: center;
	}

	.Typewriter {
		span {
			font-size: unset !important;
			line-height: unset !important;
			color: #1f1f30;
		}
	}
	h1 {
		font-weight: bold;
		font-size: 64px;
		line-height: 70px;
		text-align: center;
	}
	.intro {
		margin-bottom: 50px;
	}
	span {
		font-size: 20px;
		line-height: 28px;
		text-align: center;
		margin: auto;
	}
	.span1span1span1 {
		display: none;
	}
}
.hero-featured {
	margin-top: 20px;
	margin-bottom: 70px;
	position: relative;
	img {
		z-index: 2;
		position: relative;
	}
}
.hero-sm {
	img {
		width: 100%;
		max-height: 160px;
	}
}
@media screen and (min-width: 768px) and (max-width: 991px) {
	.hero-main-wrapper {
		padding-top: 110px;
	}

	.hero-featured {
		margin-top: 100px;
		margin-bottom: 100px;
		position: relative;
	}
	.hero-main {
		margin-top: -30px;
		margin-bottom: -80px;
		.hero-typingText {
			font-size: 48px !important;
			line-height: 52px !important;
		}
		h1 {
			font-size: 48px !important;
			line-height: 52px !important;
		}
		span {
			font-size: 18px;
			line-height: 25.2px;
		}
	}

	.hero-featured {
		margin-top: 100px;

		position: relative;
	}
}

@media screen and (min-width: 768px) {
	.hero-featured-sm-one {
		position: absolute;
		right: 0;
		z-index: 1;
		margin-top: -70px;
	}
	.hero-featured-sm-two {
		position: absolute;
		left: 0;
		z-index: 2;
		margin-top: -220px !important;
	}
	.hero-featured-sm-three {
		position: absolute;
		right: 0;
		z-index: 3;
		margin-top: -40px;
	}
	.minMedium {
		display: block;
	}
	.maxMedium {
		display: none !important;
	}
	.hero-featured {
		img {
			width: 80%;
			margin: auto;
		}
	}
}

.hero-main-wrapper {
	padding-top: 50px;
	padding-bottom: 50px;
}
@media screen and (min-width: 992px) {
	.hero-main-wrapper {
		padding-top: 50px;
		padding-bottom: 0px;
	}
}

@media screen and (max-width: 767px) {
	.hero-main-wrapper {
		.hero-subtext {
			margin-top: 100px !important;
		}
	}

	.hero-main {
		margin-top: -30px;
		margin-bottom: -80px;
		.hero-typingText {
			font-size: 36px;
			line-height: 40px;
		}
		h1 {
			font-size: 36px;
			line-height: 40px;
		}

		span {
			font-size: 16px;
			line-height: 22.4px;
		}
	}
	.hero-featured-sm-one {
		display: none;
	}
	.hero-featured-sm-two {
		display: none;
	}
	.hero-featured-sm-three {
		display: none;
	}
	.minMedium {
		display: none !important;
	}
	.maxMedium {
		display: block !important;
		width: 100%;
	}
	.hero-featured {
		img {
			margin: auto;
		}
	}
}
@media screen and (min-width: 768px) {
	.hero-featured {
		img {
			margin: auto;
		}
	}
	.h1-one {
		display: block;
	}
	.h1-two {
		display: none;
	}
}
@media screen and (max-width: 767px) {
	.h1-two {
		display: block;
	}
	.h1-one {
		display: none;
	}
	// .hero-main {
	// 	// h1 {
	// 	// 	font-size: 36px;
	// 	// 	line-height: 43px;
	// 	// }
	// 	// p {
	// 	// 	font-size: 16px !important;
	// 	// 	width: 95% !important;
	// 	// }
	// }
	// span {
	// 	font-size: 16px !important;
	// }
}
@media screen and (max-width: 470px) {
	.hero-sm {
		img {
			display: none !important;
		}
	}

	.hero-typingText {
		flex-direction: column !important;
		column-gap: 0px;
		margin-top: -10px;

		.Typewriter {
			min-height: 40px;
		}
	}
}

@media screen and (max-width: 767px) {
	.hero-main-wrapper {
		.hero-subtext {
			margin-top: 100px !important;
		}
	}
	.hero-featured-sm-one {
		display: none;
	}
	.hero-featured-sm-two {
		display: none;
	}
	.hero-featured-sm-three {
		display: none;
	}
	.minMedium {
		display: none !important;
	}
	.maxMedium {
		display: block !important;
		width: 100%;
	}
	.hero-featured {
		img {
			margin: auto;
		}
	}
}
@media screen and (min-width: 768px) {
	.hero-featured {
		img {
			margin: auto;
		}
	}
	.h1-one {
		display: block;
	}
	.h1-two {
		display: none;
	}
}
@media screen and (max-width: 767px) {
	.h1-two {
		display: block;
	}
	.h1-one {
		display: none;
	}
	.hero-main {
		h1 {
			font-size: 36px;
			line-height: 43px;
		}
		p {
			font-size: 16px !important;
			width: 95% !important;
		}
	}
	// span {
	// 	font-size: 16px !important;
	// }
}

@media screen and (max-width: 400px) {
	.hero-main {
		h1 {
			font-size: 30px;
			line-height: 37px;
		}

		.hero-typingText {
			font-size: 30px;
			line-height: 37px;
		}
	}
	// span {
	// 	font-size: 16px !important;
	// }
}

@media screen and (max-width: 330px) {
	.hero-main {
		h1 {
			font-size: 25px;
			line-height: 32px;
		}

		.hero-typingText {
			font-size: 25px;
			line-height: 32px;
		}
	}
	// span {
	// 	font-size: 16px !important;
	// }
}
@media screen and (max-width: 400px) {
	.hero-sm {
		img {
			display: none !important;
		}
	}
}
