.section-seven {
	background: #f5f6fa;
	border-radius: 36px;

	> div {
		display: flex;
		overflow: hidden;
	}

	.text-container {
		padding: 75px 50px;
		flex-basis: 60%;
	}
	.image-container {
		align-self: flex-end;
		flex-basis: 40%;
		display: none;

		img {
			max-width: 100% !important;
			margin: 0 !important;
		}
	}
	.container {
		padding: 68px 65px 0px 65px;
		margin-bottom: 140px;
	}
	h2 {
		font-weight: bold;
		font-size: 36px;
		line-height: 50.4px;
		margin-bottom: 28px;
		max-width: 80%;
	}
	p {
		// font-weight: 500;
		font-size: 16px;
		line-height: 22.4px;
	}
	.device {
		max-width: 170%;
		margin-top: -130px;
		margin-left: -150px;
	}
	.app {
		margin-top: 41px;

		display: flex;
		flex-direction: row;
		justify-content: left;
		align-items: center;
		cursor: pointer;
		img {
			cursor: pointer;
			margin-right: 25px;
			border-radius: 5px;
			-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
			transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
			&:after {
				content: "";
				z-index: -1;
				box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
				opacity: 0;
				-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
				transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
			}
			&:hover {
				-webkit-transform: scale(1.05, 1.05);
				transform: scale(1.05, 1.05);
				&::after {
					opacity: 1;
				}
			}
		}
	}
}
@media screen and (min-width: 992px) {
	.section-seven {
		p {
			max-width: 90%;
		}
	}
}

@media screen and (max-width: 767px) {
	.section-seven {
		margin-top: 100px;
		border-radius: 0px;
		h2 {
			font-size: 27px;
			line-height: 36px;
			margin-bottom: 20px !important;
			max-width: 100%;
			text-align: center;
		}

		p {
			display: none;
		}

		.container {
			background: #f5f6fa;
			border-radius: 36px;
			padding: 68px 65px 0px 65px;
			// margin-top: -150px;
			// width: auto ;

			.app {
				margin-top: 41px;
				margin-bottom: 55px;
				display: flex;
				flex-direction: column;
				// justify-content: space-evenly;
				align-items: center;
				justify-content: center;
				column-gap: 10px;
				img {
					width: 100%;
					min-width: 170px;
					margin-bottom: 25px;
					margin-right: 0px !important;
				}
			}
		}
	}

	.app-applestore {
		display: none;
	}

	.app-applestore1 {
		display: block !important;
	}

	.app-playstore {
		display: none;
	}

	.app-playstore1 {
		display: block !important;
	}
}

@media screen and (min-width: 768px) {
	.app-applestore {
		display: block !important;
	}

	.app-applestore1 {
		display: none;
	}

	.app-playstore {
		display: block !important;
	}

	.app-playstore1 {
		display: none;
	}
}
@media screen and (min-width: 991px) {
	.section-seven {
		.image-container {
			display: block;
			margin-right: -25px;
		}
	}
}
@media screen and (min-width: 768px) and (max-width: 991px) {
	.section-seven {
		margin-top: 100px;

		h2 {
			font-size: 30px;
			line-height: 39px;
			margin-bottom: 20px !important;
			max-width: 80%;
			margin: auto;
		}

		p {
			max-width: 80%;
			margin: auto;
		}
		.app {
			margin-top: 41px;
			margin-bottom: 55px;
			display: flex;
			flex-direction: row;
			// justify-content: space-evenly;
			align-items: center;
			justify-content: center;
			column-gap: 50px;
			img {
				width: 250px;
				margin-bottom: 25px;
				// margin-right: 0px !important;
			}
		}
		.container {
			background: #f5f6fa;
			border-radius: 36px;
			padding: 68px 21px 0px 21px;
			margin-bottom: 70px;
			text-align: center;
			// max-width: 90%;
		}

		h2 {
			margin: auto;
		}
	}
}
@media screen and (max-width: 1199px) {
	.section-seven {
		.device {
			max-width: 100%;
			margin: auto;
		}
	}
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
	.section-seven {
		.container {
			h2 {
				max-width: 100%;
			}
		}
		.device {
			margin-top: -60px;
			max-width: 170%;
			margin-left: -150px;
		}
	}
}

@media screen and (max-width: 480px) {
	.section-seven {
		.app img {
			cursor: pointer;
			margin-right: 15px;
			border-radius: 5px;
			transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
			width: 130px;
			height: auto;
		}
	}
}

@media screen and (max-width: 350px) {
	.section-seven {
		.app img {
			cursor: pointer;
			margin-right: 10px;
			border-radius: 5px;
			transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
			width: 100px;
			height: auto;
		}
	}
}
