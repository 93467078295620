.cardxl {
	width: 90%;
	padding-left: 40px;
	margin-top: 30px;
}

@media screen and (max-width: 767px) {
	.cardxl {
		width: auto !important;
	}
}
