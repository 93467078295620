.bca-page {
	.section-five {
		background: #fff !important;
		padding: 0px !important;
		padding-bottom: 100px !important;
		
	}

.bca-herodescription  {
	margin-bottom: 10px;
	color:#41415A;
}
	.heroContainer {
		position: relative;
	}
	.section-eight {
		margin-top: 100px;
	}

@media screen and (max-width:567px) {
	.bca-herodescription {
		display: none;
	}

	.bca-herodescription-mobile {
		display: block;
		margin-bottom: 10px;
	}
}


@media screen and (min-width:568px) {
	.bca-herodescription-mobile {
		display: none;
	}

	.bca-herodescription {
		display: block;
	}

	
}
	.hero {
		@media screen and (min-width:1400px){
		min-height: 35rem;
		}
		// margin-bottom: 10rem;
		// height: 50vh;
		 

		
		img {
			position: absolute;
			right: 0;
			top: 0%;

			width: 55%;
			margin-top: -80px !important;
			// margin-top: 2rem;
		}
		h1 {
			max-width: 450px;
		}
	}
	.footer {
		.btn-default {
			&:hover {
				color: #fff;
			}
		}
		.social {
			.fab {
				color: #5260fd !important;
			}
		}
	}
	.countryselect {
		.countryselectform {
			background-color: #f3f4ff;
			border: none;
			width: 20%;
			height: 20px;
			margin-top: 5px;
		}
	}
}
@media screen and (max-width:991px) {
	.bca-page {
		.custom10row {
			flex-direction: column-reverse;
		}
		.hero {
			margin-bottom: 10px;
			img {
				display: none;
			}
		}
		.section-nine-bca {
			margin-bottom: 100px;
		}
		.bca-p {
			padding-bottom: 50px !important;
		}
		// .section-five {
		// 	margin-top: -100px;
		// }
		.section-eight {
			margin-top: 20px;
		}
		
	}
}
@media screen and (max-width: 767px) {
	.bca-page
	 {
		
		.countryselect {
			.countryselectform {
				width: 10px;
			}
		}
		.section-eight {
			margin-top: 0px;
		}
	}
}
@media screen and (min-width:992px) {
	.bca-page {

		.cardmd p {
			width: 90%;
		}

		.section-nine-bca-container {
			margin-bottom: 50px;
		}
		.custom10one {
			padding-right: 40px;
		}

			.section-ten-row {
		padding-top: 100px;
		padding-bottom: 100px;
	}
	}
}
@media screen and (max-width:1199px) and (min-width:992px) {
	.bca-page {
		.hero {
			img {
				max-width: 550px;
				margin-top: 70px;
			}
		}
	}
}
