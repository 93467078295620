.section-eleven {
	margin-top: 50px;
	@media screen and (min-width: 992px) {
		// padding-bottom: 150px;

		.featuredDevice {
			overflow: hidden;
			padding: 40px 40px 0 40px;
		}
	}
	@media screen and (max-width: 991px) {
		// padding-bottom: 150px;

		.row {
			overflow: hidden;
			padding-top: 50px;
		}

		.featuredDevice {
			margin-top: 5rem;
		}
	}

	@media screen and (max-width: 767px) {
		// padding-bottom: 150px;

		.featuredDevice {
			margin-top: 2rem;
		}
	}
	img {
		width: 100%;
		max-height: 1000px;
		margin: auto;
		transition: transform 2.5s ease;
		&:hover {
			transform: scale(1.05);
		}
	}
	.imghoverzoom {
		overflow: hidden;
		width: 100%;
		height: auto;
	}

	.featuredDevice {
		position: relative;
	}

	.featuredDeviceImage {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
	}

	.container {
		background-color: var(--primary-tint);
		border-radius: 20px;
	}
}
@media screen and (max-width: 991px) {
	.section-eleven {
		.imghoverzoom {
			overflow: hidden;
			width: 80%;
			margin: auto;
			height: auto;
		}
	}
}

@media screen and (max-width: 767px) {
	.section-eleven {
		// padding-bottom: 150px !important;
	}
}
