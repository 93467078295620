.invoice-page {

	.section-five {
		background: #fff !important;
		padding: 0px !important;
		padding-bottom: 100px !important;
	}
	.section-eight {
		margin-top: 200px;
	}

	@media screen and (max-width: 991px) {
		.section-eight {
		margin-top: 100px;
	}

	.section-ten .customImageB {
    margin-bottom: -20px;
}
	}
	.hero {
		padding-top: 50px;
		// h2 {
		// 	font-weight: bold;
		// 	font-size: 48px;
		// 	line-height: 59px;
		// }
	}
	.subtitle-text {
		background: #F9F4EE !important;
		color: #F07C11 !important;
		padding: 8px 16px;
		font-weight: 500;
		font-size: 16px;
		line-height: 16px;
		width: fit-content;
		border-radius: 8px;
	}
	.footer {
		.btn-default {
			&:hover {
				color: #fff;
			}
		}
		.social {
			.fab {
				color: #5260fd !important;
			}
		}
	}
}
@media screen and (max-width:991px) {

}
@media screen and (max-width: 767px) {
	.invoice-page {
		.hero {
			h2 {
				font-weight: bold;
				font-size: 38px;
				line-height: 40px;
			}
		}
		.countryselect {
			.countryselectform {
				width: 10px;
			}
		}
	}
}
@media screen and (min-width: 992px) {
	.invoice-page {
		.card-wide {
			p {
				padding: 0 140px;
			}
		}
		.invoice-cardwide {
			margin-top: 80px;
			margin-bottom: -150px;
		}
	}
}
@media screen and (min-width: 768px) {
	.invoice-page {
		.invoice-cardwide {
			padding-top: 80px;
			margin-bottom: 50px;
		}
	}
}
