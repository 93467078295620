@media screen and (max-width: 767px) {
	.section-nine {
		.container {
			margin-top: 0px;
		}
	}
}


@media screen and (min-width:768px) and (max-width:991px){
	.section-nine {
		.section-nine-bca-container
		{
			width: 90%;
			
		}
	}
}